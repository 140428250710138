@media screen and (max-width: 480x) {
  #header{
    width: 110%;
    padding: 20px;
  }
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
