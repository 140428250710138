body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'AnimeAce';
  src: local('AnimeAce'), url(./fonts/anime_ace_bb/animeace2bb_tt/animeace2_reg.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotWorld';
  src: local('RobotWorld'), url(./fonts/neon_robot/Neon\ Robot.ttf) format('truetype');
}

@font-face {
  font-family: 'RapierZero';
  src: local('RapierZero'), url(./fonts/RapierZero-325M.ttf) format('truetype');
}

@font-face {
  font-family: 'Robot';
  src: local('Robot'), url(./fonts/roboto/Roboto-Bold.ttf) format('truetype');
}

  
