/* Services Section */
#docs {
    padding: 100px 0;
    background: linear-gradient(to right, #000000 0%, #484849 100%);
    color: #fff;
    text-transform: inherit;
  }
  #docs .sdocs-desc {
    margin: 10px 10px 20px;
  }
  #docs h2 {
    color: #fff;
    text-transform: inherit;
  }
  
  .docs p {
    color: #fff;
    font-size: 1px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 60px;
    text-transform: inherit;
  }
  #docs .section-title h2::after {
    position: absolute;
    content: "";
    background: #d00087;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 30px;
  }
  #docs i.fa {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    border-radius: 50%;
    color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  }
  #docs h3 {
    font-weight: 500;
    padding: 5px 0;
    color: #fff;
  }
  #docs p {
    font-size: 22px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
  }
  
  #docs .service-desc {
    margin-bottom: 40px;
  }
  
  #docs  .plurpod{
    align-items: center;
    height: auto;
  }
  